<template>
  <div class="row">
    <div class="flex xs12" v-show="edit">
      <h5 v-show="currentManager != ''">{{ $t('districts.inputs.managerInput') }}: {{ currentManager }}</h5>
      <h5 v-show="currentIntegralManager != ''">{{ $t('districts.inputs.coordinatorInput') }}: {{ currentIntegralManager }}</h5>
    </div>
    <div class="flex xs12">
      <local-table
        class="table-hover table-striped"
        :editable="edit"
        :columns="managersFields"
        :data="managersList"
        :loading="loading"
        :top-options="[]"
      >
        <template v-slot:top>
          <div class="flex xs12 md2 offset--md2">
            <va-button
              flat
              small
              v-if="edit"
              color="primary"
              icon="fa fa-plus"
              @click.prevent="tryAdd"
            >
              {{ $t('tables.actions.add') }}
            </va-button>
          </div>
        </template>
        <template v-slot:row-actions="{ props }">
          <va-popover
            v-if="edit"
            :message="$t('tables.actions.delete')"
            placement="left"
          >
            <va-button
              flat
              small
              color="danger"
              icon="fa fa-trash"
              @click.prevent="tryDelete(props.rowData)"
            />
          </va-popover>
        </template>
      </local-table>
      <va-modal
        v-if="edit"
        size="large"
        v-model="editModal"
        cancel-class="none"
        :title="$t('layout.modals.search_user.title')"
        :ok-text="$t('modal.confirm')"
        :cancel-text="$t('modal.cancel')"
        :ok-disabled="haveErrors || isLoading"
        @ok="addUser"
        @cancel="manager = ''"
      >
        <div class="row">
          <error-notification :show="haveErrors">
            <span>{{ $t('layout.modals.search_user.already_manager') }}</span>
          </error-notification>
          <div class="flex xs12">
            <va-select
              v-model="manager"
              text-by="label"
              :options="users"
              :loading="isLoading"
              :no-options-text="$t('layout.empty')"
              :disabled="loading"
              @update-search="findAllUsers"
              searchable
            />
          </div>
        </div>
      </va-modal>
    </div>
  </div>
</template>
<script>
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'change-manager',
  components: {
    LocalTable,
  },
  props: {
    edit: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    district: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data () {
    return {
      users: [],
      managersList: [],
      currentManager: '',
      manager: '',
      editModal: false,
      isLoading: false,
    }
  },
  computed: {
    notValid () {
      return this.managersList.length === 0
    },
    haveErrors () {
      if (this.manager === '' || !this.district) return false
      return this.managersList.some(t => t && t.id === this.manager.id) || this.manager.id === this.district.manager_id
    },
    managersFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
        },
        {
          name: 'email',
          title: this.$t('tables.headings.email'),
        },
        {
          name: 'group.name',
          title: this.$t('tables.headings.group'),
        },
        {
          name: '__slot:actions',
          visible: this.edit && this.managersList.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  watch: {
    district (val) {
      if (val) {
        this.getDistrictManagers(val.id)
        this.currentManager = val.manager != null ? val.manager.name : ''
        this.currentIntegralManager = val.coordinator != null ? val.coordinator.name : ''
      }
    },
  },
  created () {
    if (this.district) {
      this.getDistrictManagers(this.district.id)
      this.currentManager = this.district.manager != null ? this.district.manager.name : ''
      this.currentIntegralManager = this.district.coordinator != null ? this.district.coordinator.name : ''
    }
  },
  methods: {
    async getDistrictManagers (districtId) {
      this.isLoading = true

      let route = '/districts/managers'
      let queries = []
      if (districtId) {
        queries.push(`district=${districtId}`)
      }

      queries = queries.join('&')
      route = route + '?' + queries
      let response = false
      try {
        response = await this.$http.get(route)
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false
        return
      }

      const managers = response.data.data || []
      this.managersList = this.fixData(managers)
      this.isLoading = false
    },
    fixData (data) {
      const d = data.map(p => ({ ...p.user, manager_id: p.id }))

      for (const p of d) {
        if (p === undefined) continue

        if (p.groups.length === 0) {
          p.group = {
            name: '',
          }
        } else {
          const groups = p.groups.map(g => g.name)
          p.group = {
            name: groups.join(', '),
          }
        }
      }

      return d
    },
    fixSelectData (data) {
      for (const p of data) {
        if (p === undefined) continue
        const label = `${p.name} - ${p.email}`
        p.label = label

        if (p.groups.length === 0) {
          p.group = {
            name: '',
          }
        } else {
          const groups = p.groups.map(g => g.name)
          p.group = {
            name: groups.join(', '),
          }
        }
      }

      return data
    },
    filterData (data) {
      const d = data.filter(m => this.managersList.findIndex(u => u.id === m.id) === -1)
      return this.fixSelectData(d)
    },
    async findAllUsers (query) {
      this.isLoading = true

      let route = '/users'
      let queries = []
      if (query) {
        queries.push(`q=${query}`)
      }

      queries = queries.join('&')
      route = route + '?' + queries
      let response = false
      try {
        response = await this.$http.get(route)
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false
        return
      }

      const users = this.filterData(response.data.data) || []
      this.users = users
      this.isLoading = false
    },
    tryAdd () {
      this.editModal = true
    },
    async addUser () {
      if (this.haveErrors) {
        this.editModal = true
        return
      }

      this.isLoading = true

      const managerData = {
        district_id: this.district.id,
        user_id: this.manager.id,
      }

      try {
        await this.$http.post('districts/managers', managerData)
      } catch (e) {
        console.log('Failed to add manager', e)
        this.isLoading = false
        return
      }

      this.getDistrictManagers(this.district.id)
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      try {
        await this.$http.delete(`districts/managers/${item.manager_id}`)
      } catch (e) {
        console.log('Failed to delete manager', e)
        this.isLoading = false
        return
      }

      this.getDistrictManagers(this.district.id)
    },
  },
}
</script>
